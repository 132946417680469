import React, { useState, useEffect, useMemo } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid, Stack } from '@mui/material';
import IconPlus from 'assets/plus.svg';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import iconPicture from 'assets/image-icon.svg';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { tradeAffiliation, cities, states, terms, jobCreationStep, COUNTRY_ID } from 'constant';
import {
  useGetJobCategoryQuery,
  useGetMyCompanyQuery,
  useGetPaymentsQuery,
  useGetSubscriptionQuery,
  useGetUserTradeLicencesQuery,
  usePostAddCardMutation,
  usePostCreateCompanyMutation,
  usePostCreateJobMutation,
  usePostPaymentChargeMutation
} from 'store/slices/apiSlice';
import { json, useNavigate } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';
import { errorValidationHandler } from 'utils/errorHandler';
import moment from 'moment';
import PricingList from 'components/Job/PricingList';
import FeaturedJobList from 'components/Job/FeaturedJobList';
import ChargePaymentComponent from 'components/Payment/charge';
import countryData from 'constant/country.json';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import { HandleGetAllCardsRequest } from 'api/callsHandler';

const postJobSteps = ['post-submit', 'post-price', 'post-featured', 'charge-job'];

const PostJob = () => {
  const [age, setAge] = useState('');
  const [picture, setPicture] = useState(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
  );
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));
  const [activePage, setActivePage] = React.useState(postJobSteps[0]);

  const [
    postCreateJob,
    {
      data: dataJobCreated,
      isLoading: isLoadingJobCreated,
      isSuccess: isSuccessJobCreated,
      isError: isErrorJobCreated,
      error: errorJobCreated
    }
  ] = usePostCreateJobMutation();

  const [
    postAddCard,
    {
      data: addCardData,
      isSuccess: isSuccessAddCard,
      error: addCardError,
      isError: isErrorAddCard,
      isLoading: isLoadingAddCard
    }
  ] = usePostAddCardMutation();

  const {
    data: allCardsData,
    isLoading: isLoadingGetAllCards,
    mutate: refetchGetAllCards
  } = HandleGetAllCardsRequest();

  const isJobSaved = useMemo(
    () =>
      dataJobCreated?.id && typeof dataJobCreated?.id === 'number' && dataJobCreated?.id > 0
        ? true
        : false,
    [dataJobCreated]
  );

  const [
    postCreateCompany,
    {
      isSuccess: isSuccessCompanyCreate,
      isError: isErrorCompanyCreate,
      error: companyErrorCreate,
      isLoading: isLoadingCompanyCreate
    }
  ] = usePostCreateCompanyMutation();
  const [
    postPaymentCharge,
    {
      isSuccess: isSuccessPaymentCharge,
      error: paymentChargeError,
      isError: isErrorPaymentCharge,
      isLoading: isLoadingPaymentCharge
    }
  ] = usePostPaymentChargeMutation();
  const { data: dataJobCategory, refetch: refetchJobCategory } = useGetJobCategoryQuery();
  const { data: userTradeLicence, refetch: refetchTradeLicences } = useGetUserTradeLicencesQuery();
  const { data: myCompanyData, refetch: refetchGetMyCompany } = useGetMyCompanyQuery();
  const { data: paymentsData, refetch: refetchPaymentData } = useGetPaymentsQuery();
  const { data: subscriptionData, refetch: refetchSubscriptionData } = useGetSubscriptionQuery();
  useEffect(() => {
    refetchTradeLicences();
    refetchJobCategory();
    refetchGetMyCompany();
    refetchPaymentData();
    refetchSubscriptionData();
  }, []);
  console.log({
    userTradeLicence,
    paymentsData,
    myCompanyData,
    subscriptionData
  });
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeDate = (newValue) => {
    setPayload({
      ...payload,
      start_date: newValue
    });
  };

  function uploadImage(e) {
    setPicture(URL.createObjectURL(e.target.files[0]));
    setPayload({
      ...payload,
      upload: e.target.files[0]
    });
  }
  const handleCheckBox = (event) => {
    const parent = document.querySelector(`#${event.target.name}`).children;

    let selectedValues = [];

    for (let i = 0; i < parent.length; i++) {
      const item = parent[i];
      if (['trade_affiliation'].includes(event.target.name)) {
        if (item.children[0].checked === true) {
          selectedValues.push(item.children[0].value);
        }
      } else {
        if (event.target.id !== item.children[0].id) {
          item.children[0].checked = false;
        }
      }
    }

    setPayload({
      ...payload,
      [event.target.name]: ['trade_affiliation'].includes(event.target.name)
        ? selectedValues
        : event.target.value
    });
  };

  const [payload, setPayload] = useState({
    upload:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
    start_date: new Date(),
    charge_job: '0',
    charge_job_featured: '0',
    isAgreeRule: false,
    sub_id: undefined,
    addon_id: undefined
  });

  const initialValues = {
    title: '',
    // city: '',
    // state: '',
    description: '',
    is_archive: '',
    job_category: '',
    applicants: [],
    company_name: '',

    zipcode: '',
    website: '',
    term: '',
    price: '',
    trade: '',

    country: ''
  };

  const validationSchema = yup.object({
    title: yup.string().required('Required'),
    // city:  yup.string().required('Required'),
    // state:  yup.string().required('Required'),
    description: yup.string().required('Required'),
    company_name: yup.string().required('Required'),
    zipcode: yup.number().typeError('Must be a number').required('Required'),
    website: yup
      .string()
      .notRequired()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Invalid URL'
      ),
    term: yup.string().required('Required'),
    price: yup.number().typeError('Must be a number').notRequired(),
    // trade: yup.array().min(1),
    trade: yup.string().required('Required'),
    country: yup.string()
  });

  // useEffect(()=> {
  //     if (isSuccessCompanyCreate) {
  //         onPostJob()
  //     }
  // }, [isSuccessCompanyCreate])

  const onPostJob = () => {
    let bodyPaylod = {
      ...payload,
      job_category: dataJobCategory?.results?.map((e) => e.id)?.[0]
    };
    postCreateJob(payload);
  };

  console.log({
    payload
  });

  const onSubmitData = (values) => {
    if (isJobSaved) {
      setActivePage(postJobSteps[postJobSteps.findIndex((d) => d === 'post-price')]);
    } else {
      let bodyPayload = {
        ...values,
        ...payload,
        image: payload.upload,
        images: [payload.upload],
        image_objects: payload.upload,
        start_date: moment(payload.start_date).format('YYYY-MM-DD'),
        about: '-',
        email: `${values.company_name.replaceAll(' ', '-')}@workium.com`,
        fax: '-',
        name: payload.company_name,
        phone_number: '-',
        travel_distance: 0,
        year: moment().format('YYYY-MM-DD'),
        job_category: dataJobCategory?.results?.map((e) => e.id)?.[0],
        zip_code: values.zipcode
      };

      const { applicants, ...clearPayload } = bodyPayload;

      console.log({
        clearPayload
      });

      setPayload({
        ...payload,
        ...clearPayload,
        city: filterLocation?.city?.id,
        state: filterLocation?.state?.id
      });

      postCreateJob({
        ...clearPayload,
        city: filterLocation?.city?.id,
        state: filterLocation?.state?.id
      });

      // postCreateCompany(clearPayload)
    }
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitData
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value:
          isLoadingJobCreated ||
          isLoadingCompanyCreate ||
          isLoadingPaymentCharge ||
          isLoadingAddCard ||
          isLoadingGetAllCards
      }
    });
  }, [
    isLoadingJobCreated,
    isLoadingCompanyCreate,
    isLoadingPaymentCharge,
    isLoadingAddCard,
    isLoadingGetAllCards
  ]);

  const [errorPrice, setErrorPrice] = useState(false);
  useEffect(() => {
    if (
      isErrorJobCreated ||
      isErrorCompanyCreate ||
      errorPrice ||
      isErrorPaymentCharge
      // || isErrorAddCard
    ) {
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      console.log({
        test: companyErrorCreate?.data
      });
      const message = errorValidationHandler(
        paymentChargeError?.data || errorJobCreated?.data || companyErrorCreate?.data || errorPrice
      )?.alert || ['Something went wrong'];
      console.log({
        message,
        errorJobCreated
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message
        }
      });
    }
  }, [
    isErrorJobCreated,
    isErrorCompanyCreate,
    errorPrice,
    isErrorPaymentCharge
    // isErrorAddCard
    // addCardError
  ]);

  const onContinuePage = () => {
    // debugger;

    if (activePage === postJobSteps[1] && !payload?.isAgreeRule) {
      setErrorPrice(['Please check that you agree on rules for job posting']);
      return false;
    }

    if (activePage === postJobSteps[1] && !payload?.sub_id) {
      setErrorPrice(['Please select one of the packages']);
      return false;
    }

    if (activePage === postJobSteps[1] && payload?.charge_job !== '0') {
      setErrorPrice(null);
      setActivePage(postJobSteps[2]);
    } else if (activePage === postJobSteps[2]) {
      setErrorPrice(null);
      setActivePage(postJobSteps[3]);
    }
  };

  const onBackPage = () => {
    if (activePage === postJobSteps[3]) {
      setActivePage(postJobSteps[2]);
    } else if (activePage === postJobSteps[2]) {
      setActivePage(postJobSteps[1]);
    } else if (activePage === postJobSteps[1]) {
      setActivePage(postJobSteps[0]);
    }
  };

  const onSuccessCharge = (token) => {
    postAddCard({
      token: token.id
    });
  };
  useEffect(() => {
    if (isSuccessAddCard) {
      if (allCardsData?.data?.data?.length > 0) {
        // debugger;
        postPaymentCharge({
          // p_id: dataJobCreated?.id,
          sub_id: payload?.sub_id,
          // token: token?.id,
          // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
          card_id: allCardsData?.data?.data?.[0]?.id,
          addon_id: payload?.addon_id,
          payment_type: 'job',
          job_id: dataJobCreated?.id,
          coupon_id: payload?.coupon ? payload?.coupon?.id : undefined
        });
      } else {
        refetchGetAllCards();
        // setTimeout(() => {
        //   postPaymentCharge({
        //     // p_id: dataJobCreated?.id,
        //     sub_id: payload?.sub_id,
        //     // token: token?.id,
        //     // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
        //     card_id: allCardsData?.data?.data?.[0]?.id,
        //     addon_id: payload?.addon_id,
        //     payment_type: 'job',
        //     job_id: dataJobCreated?.id
        //   });
        // }, 2000);
      }
    }
  }, [isSuccessAddCard, allCardsData]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccessPaymentCharge) {
      navigate(routerPath.jobs.manageJob);
    }
  }, [isSuccessPaymentCharge]);

  useEffect(() => {
    if (isSuccessJobCreated) {
      setActivePage(postJobSteps[1]);
    }
  }, [isSuccessJobCreated]);

  const [filterLocation, setFilterLocation] = useState({
    country: '',
    city: '',
    state: ''
  });
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              {activePage === postJobSteps[postJobSteps.findIndex((d) => d === 'post-price')] && (
                <>
                  <h2 className="title-2 mb-4">Post Job Opening</h2>
                  <PricingList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job'}
                    onVerifyPromoCode={(value) => {
                      setPayload({
                        ...payload,
                        coupon: value
                      });
                    }}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                    onAggreePostingRule={(value) => {
                      setPayload({
                        ...payload,
                        isAgreeRule: value
                      });
                    }}
                    onClickBack={() => {
                      setActivePage((prev) => prev - 1);
                    }}
                  />
                </>
              )}
              {activePage ===
                postJobSteps[postJobSteps.findIndex((d) => d === 'post-featured')] && (
                <>
                  <h2 className="title-2 mb-4">Feature Job Post </h2>
                  <FeaturedJobList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job_featured'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                  />
                </>
              )}

              {activePage === postJobSteps[postJobSteps.findIndex((d) => d === 'charge-job')] && (
                <ChargePaymentComponent
                  postType="job"
                  onSuccessCharge={onSuccessCharge}
                  onClickBack={() => {
                    setActivePage(postJobSteps[postJobSteps.length - 2]);
                  }}
                  addCardError={addCardError}
                  payload={payload}
                />
              )}

              {activePage === postJobSteps[postJobSteps.findIndex((d) => d === 'post-submit')] && (
                <>
                  <form onSubmit={formik.handleSubmit}>
                    <h2 className="title-2 mb-4">Post Job Opening</h2>
                    <Grid container spacing={3} className="mb-2">
                      <Grid item md={3}>
                        <label className="text-dark">Job Ad</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter job name"
                          value={formik.values.title}
                          name={'title'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.title && (
                          <div className="text-danger mt-1">{formik.errors.title}</div>
                        )}
                      </Grid>
                    </Grid>

                    <h4 className="title-3">Company Information</h4>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <label className="text-dark">Company name:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter company name"
                          value={formik.values.company_name}
                          name={'company_name'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.company_name && (
                          <div className="text-danger mt-1">{formik.errors.company_name}</div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      {/* <Grid item md={3}>
                        <label className="text-dark">Country:</label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={
                            countryData.find(
                              (item) => item.country_id === filterLocation?.country?.id
                            )?.country_name
                          }
                          options={countryData.map((e) => {
                            return {
                              label: e.country_name,
                              id: e.country_id
                            };
                          })}
                          onChange={(e, value) => {
                            setFilterLocation({
                              ...filterLocation,
                              country: value
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                              variant="outlined"
                              className="form-control"
                            />
                          )}
                          disabled={isJobSaved}
                        />
                      </Grid> */}
                      <Grid item md={3}>
                        <label className="text-dark">State:</label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={
                            stateData?.find((item) => item.state_id == filterLocation?.state?.id)
                              ?.state_name ?? ''
                          }
                          options={stateData
                            .filter((f) => +f.country_id == COUNTRY_ID)
                            .map((e) => {
                              return {
                                label: e.state_name,
                                id: e.state_id
                              };
                            })}
                          onChange={(e, value) => {
                            setFilterLocation({
                              ...filterLocation,
                              state: value
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                              placeholder="State"
                              variant="outlined"
                              className="form-control"
                            />
                          )}
                          disabled={isJobSaved}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <label className="text-dark">City:</label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={
                            cityData[2].data.find(
                              (city) => city.city_id == filterLocation?.city?.id
                            )?.city_name ?? ''
                          }
                          options={cityData[2].data
                            .filter((f) => f.state_id == filterLocation?.state?.id)
                            .map((e) => {
                              return {
                                label: e.city_name,
                                id: e.city_id
                              };
                            })}
                          onChange={(e, value) => {
                            console.log({
                              value
                            });
                            setFilterLocation({
                              ...filterLocation,
                              city: value
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                              variant="outlined"
                              className="form-control"
                            />
                          )}
                          disabled={isJobSaved}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <label className="text-dark">Website:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Website"
                          value={formik.values.website}
                          name={'website'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.website && (
                          <div className="text-danger mt-1">{formik.errors.website}</div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className="mb-2">
                      <Grid item md={3}>
                        <label className="text-dark">ZIP Code:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter ZIP code"
                          value={formik.values.zipcode}
                          name={'zipcode'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.zipcode && (
                          <div className="text-danger mt-1">{formik.errors.zipcode}</div>
                        )}
                      </Grid>
                    </Grid>

                    <h4 className="title-3">Uploads (Optional)</h4>
                    <div className="uploads-container mr-2">
                      <input type="file" onChange={uploadImage} disabled={isJobSaved} />
                      <img
                        src={
                          typeof payload.upload === 'string'
                            ? payload.upload
                            : URL.createObjectURL(payload.upload)
                        }
                        alt=""
                        className="upload-preview"
                      />
                      <div className="uploads-label">
                        <img src={iconPicture} alt="" />
                        <div>Upload Picture</div>
                      </div>
                    </div>

                    <h4 className="title-3">About Job</h4>
                    <Grid container spacing={3}>
                      <Grid item md={6} className="mb-2">
                        <label>Description:</label>
                        <textarea
                          className="form-control"
                          rows="10"
                          placeholder="Enter Description"
                          value={formik.values.description}
                          name={'description'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.description && (
                          <div className="text-danger mt-1">{formik.errors.description}</div>
                        )}
                      </Grid>
                    </Grid>

                    <h4 className="title-3">Job Price</h4>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <label className="text-dark">Price:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Price"
                          value={formik.values.price}
                          name={'price'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.price && (
                          <div className="text-danger mt-1">{formik.errors.price}</div>
                        )}
                      </Grid>
                      <Grid item md={3}>
                        <label className="text-dark">Start date:</label>
                        {/* <input type="text" className="form-control" /> */}
                        <div className="form-date">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="MM/dd/yyyy"
                              value={payload.start_date}
                              onChange={handleChangeDate}
                              renderInput={(params) => <TextField {...params} />}
                              disabled={isJobSaved}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className="mb-3">
                      <Grid item md={3}>
                        <label className="text-dark">Terms:</label>

                        <FormControl fullWidth>
                          <Select
                            value={formik.values.term}
                            onChange={formik.handleChange}
                            name={'term'}
                            sx={{
                              height: '45px',
                              borderRadius: '8px'
                            }}
                            disabled={isJobSaved}>
                            {terms.map((e, idx) => (
                              <MenuItem key={idx} value={e.name}>
                                {e.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {!!formik.errors.term && (
                          <div className="text-danger mt-1">{formik.errors.term}</div>
                        )}
                      </Grid>
                    </Grid>
                    {/*
                            <h4 className="title-3">Job Category</h4>
                            <Grid container spacing={3} className="mb-3">
                                <Grid item md={6}>
                                <div className="d-flex flex-wrap" id={'job_category'}>
                                        {
                                            !!dataJobCategory?.results && dataJobCategory?.results?.map(({name, id }, idx)=> 
                                            <div className='check-btn mr-1' key={idx}>
                                                <input type="checkbox" id={`job_category-${id}`}  name="job_category" onChange={handleCheckBox} value={id} />
                                                <label htmlFor={`job_category-${id}`}>{name}</label>
                                            </div>)
                                        }
                                    </div>
                                </Grid>

                                    </Grid>*/}

                    <h4 className="title-3">Trade</h4>
                    <Grid container className="mb-3">
                      <Grid item md={3}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Trade"
                          value={formik.values.trade}
                          name={'trade'}
                          onChange={formik.handleChange}
                          disabled={isJobSaved}
                        />
                        {!!formik.errors.trade && (
                          <div className="text-danger mt-1">{formik.errors.trade}</div>
                        )}
                      </Grid>
                    </Grid>

                    <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ pt: 2 }}>
                      {!isJobSaved && (
                        <Box>
                          <button
                            disabled={isJobSaved}
                            type="submit"
                            className="btn btn-success mr-1">
                            <img src={IconPlus} className="bottom mr-1" /> Finish
                          </button>
                        </Box>
                      )}
                      {isJobSaved && (
                        <Box>
                          <button className="btn btn-success ml-1" onClick={onContinuePage}>
                            <img src={IconPlus} className="top mr-1" /> Continue
                          </button>
                        </Box>
                      )}
                    </Stack>
                  </form>
                </>
              )}
            </div>
            {/* <FooterDashboard /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PostJob;
